export default [
  { id: 0, name: '空手', lefthand: true },
  { id: 1, name: '短劍', lefthand: true },
  { id: 2, name: '單手劍', lefthand: true },
  { id: 3, name: '雙手劍', lefthand: false },
  { id: 4, name: '單手槍', lefthand: true },
  { id: 5, name: '雙手槍', lefthand: false },
  { id: 6, name: '單手斧', lefthand: true },
  { id: 7, name: '雙手斧', lefthand: false },
  { id: 8, name: '鈍器', lefthand: true },
  { id: 9, name: '單手杖', lefthand: true },
  { id: 10, name: '弓', lefthand: false },
  { id: 11, name: '拳刃', lefthand: false },
  { id: 12, name: '書', lefthand: true },
  { id: 13, name: '拳套', lefthand: true },
  { id: 14, name: '樂器/鞭子', lefthand: false },
  { id: 15, name: '雙手杖', lefthand: true },
  { id: 16, name: '風魔飛鏢', lefthand: false },
  { id: 17, name: '手槍', lefthand: false },
  { id: 18, name: '來福槍', lefthand: false },
  { id: 19, name: '霰彈槍', lefthand: false },
  { id: 20, name: '格林機槍', lefthand: false },
  { id: 21, name: '榴彈槍', lefthand: false },
];
