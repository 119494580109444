export const SET_BASE_LEVEL = 'SET_BASE_LEVEL';
export const SET_JOB_LEVEL = 'SET_JOB_LEVEL';
export const SET_JOB = 'SET_JOB';
export const SET_STAT = 'SET_STAT';
export const SET_OTHER_STAT = 'SET_OTHER_STAT';
export const LOAD_SAVE_DATA = 'LOAD_SAVE_DATA';

export const UPDATE_ASPD_WEAPON_ID = 'UPDATE_ASPD_WEAPON_ID';
export const UPDATE_ASPD_LEFTHAND_ID = 'UPDATE_ASPD_LEFTHAND_ID';
export const UPDATE_ASPD_EQUIP_MOD = 'UPDATE_ASPD_EQUIP_MOD';
export const UPDATE_ASPD_EQUIP_FIXED = 'UPDATE_ASPD_EQUIP_FIXED';
export const UPDATE_ASPD_SKILL_MOD = 'UPDATE_ASPD_SKILL_MOD';
export const UPDATE_ASPD_POTION_MOD = 'UPDATE_ASPD_POTION_MOD';
export const UPDATE_ASPD_ADDITIONAL_MOD = 'UPDATE_ASPD_ADDITIONAL_MOD';

export const UPDATE_HP_ADD_MOD = 'UPDATE_HP_ADD_MOD';
export const UPDATE_HP_MULTI_MOD = 'UPDATE_HP_MULTI_MOD';
export const UPDATE_SP_ADD_MOD = 'UPDATE_SP_ADD_MOD';
export const UPDATE_SP_MULTI_MOD = 'UPDATE_SP_MULTI_MOD';

export const UPDATE_BUFF_SKILL = 'UPDATE_BUFF_SKILL';
